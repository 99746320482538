import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { LoaderIcon } from '../../../components/Icons'
import { ExclusiveTestsModal, TextModal } from '../../../components/Modals';
import CalendarBox from './Calendar';
import { useSchedule } from './hook';
import Ranges from './Ranges';
import Shops from './Shops';
import {modals_info} from '../../../data/modals'

export const ArrowCalendar = (props) => {
  // console.log(props);
  return (
    <button className="w-full h-full flex items-center justify-center" style={{zIndex: 199}}>
      <svg className={`${props.left ? 'rotate-180' : 'rotate-0'} fill-[#16325C]`} width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M1.49755 15C1.17714 15 0.856579 14.8954 0.6124 14.6861C0.123259 14.2676 0.123259 13.5895 0.6124 13.171L7.24201 7.49992L0.6124 1.82898C0.123259 1.41045 0.123259 0.73243 0.6124 0.313899C1.10154 -0.104633 1.89395 -0.104633 2.38309 0.313899L9.89629 6.74254C10.3854 7.16107 10.3854 7.83909 9.89629 8.25763L2.38309 14.6863C2.1393 14.8962 1.81842 15 1.49755 15Z"/>
      </svg>
    </button>
  )
}

const AppointmentSchedule = () => {
  const generalData = useOutletContext();
  const navigate = useNavigate()

  const hookData = useSchedule(generalData)

  const {
    appointment, 
    ranges, 
    dates,

    updateAppointment, 
    searchDates, 
    searchRangesByDay,

    setShowExtractionsModal, 
    showExtractionsModal, 
    setShowExtractionsBloodModal,
    showExtractionsBloodModal,
    showSaturdaysModal, 
    setShowSaturdaysModal,
    showThuesdaysModal,
    setShowThuesdaysModal,
    showMicologicoModal,
    setShowMicologicoModal,
    // showSudorModal,
    // setShowSudorModal,
    showFridaysModal, 
    setShowFridaysModal,

    loading,
    isLoading,
    errors
  } = hookData
  
  const {extracciones, sabados, micologico, fridays,extraccionesSangre,martes} = modals_info

  return (
    <>
      <>
        {(!isLoading && showSaturdaysModal && appointment.test.value !== "otros-hisopados" ) && <ExclusiveTestsModal
          title="Las siguientes prácticas <br /> no se realizan los días sábados"
          onClose={() => {
            setShowSaturdaysModal(false)
            navigate('/agenda/paciente')
          }}
          tests1={sabados.left}
          tests2={sabados.right}
        />}
        {(!isLoading && showThuesdaysModal && appointment.test.value !== "otros-hisopados" ) && <ExclusiveTestsModal
          title="Las siguientes prácticas <br /> no se realizan los días Martes"
          onClose={() => {
            setShowThuesdaysModal(false)
            navigate('/agenda/paciente')
          }}
          tests1={martes.left}
          tests2={martes.right}
        />}
        {(!isLoading && showFridaysModal && appointment.test.value === "extraccion-muestra") && <ExclusiveTestsModal
          title={fridays.title}
          onClose={() => {
            setShowFridaysModal(false) 
            if(appointment?.hour.hour >= 12 && appointment.shop.value === "SUC14"){
              setShowExtractionsBloodModal(true)
            }else
              navigate('/agenda/paciente')
          }}
          tests1={fridays.left}
          tests2={fridays.right}
        />}
        {(!isLoading && showExtractionsModal) && <ExclusiveTestsModal
          title="Las siguientes prácticas requieren extracción <br /> exclusivamente entre las 7:00am y las 9:30am"
          onClose={() => setShowExtractionsModal(false)}
          tests1={extracciones.left}
          tests2={extracciones.right}
        />}
        {(!isLoading && showExtractionsBloodModal && appointment.test.value === "extraccion-muestra") && <ExclusiveTestsModal
          title="Las siguientes prácticas requieren extracción <br /> exclusivamente entre las 7:00am y las 12:00hs"
          onClose={() => {
            setShowExtractionsBloodModal(false)
            navigate('/agenda/paciente')
          }}
          tests1={extraccionesSangre.left}
          tests2={extraccionesSangre.right}
        />}
        {showMicologicoModal && <TextModal
          details={micologico.details}
          title={micologico.title}
          onClose={() => setShowMicologicoModal(false)}
        />}
        {/* {showSudorModal && <TextModal
          details={sudor.details}
          title={sudor.title}
          onClose={() => setShowSudorModal(false)}
        />} */}
      </>
      <div className='flex justify-center pb-10 lg:pb-0'>
        <div className='w-full flex-1 max-w-[500px] lg:max-w-[952px] lg:w-[952px] flex flex-col items-center lg:items-start justify-center'>
          <div className='mb-7 w-full'>
            <Link to="/agenda" className='flex items-center justify-start gap-2 max-w-max'>
              <ArrowCalendar left color="fill-cyan h-3" /> 
              <p className='text-md font-bold text-cyan opacity-60'>Volver</p>
            </Link>
            <h1 className='mt-4 text-xl sm:text-[28px] xl:text-4xl font-bold'>
              Elegí <span className='text-cyan'>tu turno</span> para {appointment?.test?.name.toLowerCase()}
            </h1>
          </div>

          <div className='flex flex-col gap-6 w-full'>
            <Shops data={{appointment, searchDates, updateAppointment}} />

            {appointment?.shop?.value && appointment?.date && (
              appointment.shop.value !== "DOMI_EXTRACCION" ? (
                <div>
                  <h6 className='mb-4'>Escogé en qué día y hora asistirás al turno</h6>
                  <div className='grid grid-cols-1 lg:grid-cols-4 gap-6 min-h-[272px]'>
                    <div className='bg-white shadow-2 rounded-lg flex items-center justify-center lg:col-span-2 '>
                      {loading?.calendar ? (
                        <LoaderIcon />
                      ) : errors?.calendar ? (
                        <p>{errors?.calendar}</p>
                      ) : (
                        <CalendarBox data={{appointment, searchRangesByDay, dates, updateAppointment}} />
                      )}
                    </div>

                    <div className='bg-white shadow-2 rounded-lg lg:col-span-2 w-full min-h-full flex items-center justify-center overflow-x-hidden overflow-y-scroll  max-h-[272px] custom-scrollbar'>
                      {loading.ranges ? <LoaderIcon /> : <Ranges data={{ranges, appointment, updateAppointment}} />}
                    </div>
                  </div>
                </div>) : 
                ( <div>
                    <div className='grid grid-cols-1  min-h-[130px]'>
                      <div className='bg-white shadow-2 rounded-lg flex items-center justify-center p-3 text-[25px]' style={{ lineHeight: '1.3' }}>
                      Estimado paciente: Seleccione la opción continuar, complete sus datos personales  y adjunte su orden médica .
                      </div>
                    </div>
                </div>))}
          </div>

          <div className='w-full flex md:justify-between gap-4 pt-4 pb-5 md:pb-10'>
            <Link
              to="/agenda"
              className='w-full lg:max-w-max border border-cyan text-cyan py-4 px-6 400:px-12 rounded-lg flex-1 opacity-70 md:flex-0 text-center'
            >
              Volver
            </Link>
            <button
              disabled={!appointment.hour}
              onClick={() => {
                
                if(appointment?.date && new Date(appointment?.date).getDay() === 6) setShowSaturdaysModal(true)                
                
                else if(appointment?.date && new Date(appointment?.date).getDay() === 5 && appointment?.test?.value === "extraccion-muestra") 
                
                  setShowFridaysModal(true)          
                else if(appointment?.date && new Date(appointment?.date).getDay() === 2 && appointment?.test?.value === "extraccion-muestra") 
                
                  setShowThuesdaysModal(true)

                else if(appointment?.hour.hour >= 12 && appointment.shop.value === "SUC14" && appointment?.test?.value === "extraccion-muestra") 
                  setShowExtractionsBloodModal(true)                
                
                  else navigate('/agenda/paciente')
              }}
              className={`w-full lg:max-w-max text-white shadow-6 py-4 px-6 400:px-11 rounded-lg flex-1 md:flex-0 text-center ${appointment.hour?.id ? 'bg-cyan' : 'bg-deactivatedCyan'}`}
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentSchedule
