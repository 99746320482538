import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import authActions from "../../redux/actions/auth"
import { MaterialIcon, PersonIcon, SignOutIcon, UserIcon } from '../Icons'
import Login, { LoginBox } from '../Login'
import othersActions from '../../redux/actions/others'
import { roles } from '../../data/enums'
import DivOutside from '../DivOutside'
import { useLogin } from '../Login/hook'
import moment from 'moment'




const UserMenu = ({ data: { user, setModalUserData, showUserInfo, setShowUserInfo, setShowLogin, showLogin } }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const data = useLogin(false, setShowUserInfo)
  const { step, currentStep, isLoading } = data

  const signOut = () => {
    if (!location.pathname.includes('/agenda')) navigate('/agenda')
    setShowUserInfo(false)
    dispatch(authActions.signOut())
  }

  return (
    <DivOutside
      onClose={() => setShowUserInfo(false)}
      classes={`fixed top-0 lg:top-20 lg:right-10 min-h-full flex flex-col justify-between lg:min-h-min right-0 bg-white py-4 lg:pt-7 lg:pb-0 rounded-lg shadow-left lg:shadow-2  min-w-[320px] w-[320px] max-w-[320px] overflow-hidden z-[10000] ${user ? 'px-7' : 'lg:hidden'}`}
    >
      <div>
        <div className={`flex-1 flex justify-end lg:hidden ${user ? '' : 'pr-7'}`}>
          <MaterialIcon icon="close" pointer action={() => setShowUserInfo(false)} />
        </div>
        <div className="flex gap-3 lg:gap-0 items-center lg:justify-start lg:mt-0">
          {user && <div className='!overflow-hidden'>
            <UserIcon />
          </div>}
          {!user && (
            <p className='text-[22px] min-w-max pl-7'>Inicio de sesión</p>
          )}
          {user && <div>
            <h5 className="text-left font-[600] text-[22px] pl-2">{user.name} {user.surname}</h5>
            {user?.specialty && <p className='text-deactivatedDarkBlue text-sm flex items-center gap-1'>
              <MaterialIcon icon="badge" extrastyles="text-lg" />
              <span className='-mb-1'>{user?.specialty === "Otra" ? "Por definir" : user?.specialty}</span>
            </p>}
          </div>}
        </div>

        {!user && <div className='bg-darkBlue rounde px-4 py-6 mt-5 mb-10'>
          <LoginBox data={{ step, data, currentStep }} />
        </div>}

        <div className={`lg:hidden w-full mt-3 md:mt-6  py-5 flex flex-col gap-4 md:gap-5 text-left font-[400] text-cyan fill-cyan text-[20px] ${!user ? 'px-7' : 'md:py-[30px] border-t border-b'}`}>
          <Link onClick={() => showUserInfo(false)} to='/agenda' className='flex items-center gap-3 fill-cyan'>
            <MaterialIcon icon="edit_calendar" />
            <span>Pedir turno</span>
          </Link>
          <Link onClick={() => showUserInfo(false)} to='/turnos' className='flex items-center gap-3'>
            <MaterialIcon icon="event_available" />
            <span>Mis turnos</span>
          </Link>
          <Link onClick={() => showUserInfo(false)} to='/resultados' className='flex items-center gap-3'>
            <MaterialIcon icon="file_present" />
            <span>Mis resultados</span>
          </Link>
          {user.role === roles.doctor && <Link onClick={() => showUserInfo(false)} to='/pacientes' className='flex items-center gap-3'>
            <MaterialIcon icon="personal_injury" />
            <span>Pacientes</span>
          </Link>}
        </div>

        {user && <div className=''>
          <div className='py-3 md:py-[30px] flex-1'>
            <div className='flex items-center gap-3 opacity-60 mb-4 lg:mb-0 lg:hidden'>
              <PersonIcon />
              <p className='text-cyan font-[600]'>Mis datos</p>
            </div>
            <div className="flex flex-col gap-4 items-start mb-8 pl-8 lg:pl-0">
              <div className="flex flex-col items-start gap-[2px]">
                <span className="uppercase text-deactivatedDarkBlue font-bold text-[8px] lg:text-[10px]">Documento</span>
                <p className="text-[15px] lg:text-lg">{user.idType} {user.idNumber}</p>
              </div>
              <div className="flex flex-col items-start gap-[2px]">
                <span className="uppercase text-deactivatedDarkBlue font-bold text-[8px] lg:text-[10px]">Nacimiento</span>
                <p className="text-[15px] lg:text-lg">
                 {user?.birthDate === null ?
                  moment(user?.birthDate).format('DD/MM/Y')
                  : ''
                  }
                  </p>
              </div>
              <div className="flex flex-col items-start gap-[2px]">
                <span className="uppercase text-deactivatedDarkBlue font-bold text-[8px] lg:text-[10px]">Teléfono</span>
                <p className="text-[15px] lg:text-lg">{user.phone}</p>
              </div>
              <div className="flex flex-col items-start gap-[2px]">
                <span className="uppercase text-deactivatedDarkBlue font-bold text-[8px] lg:text-[10px]">Email</span>
                <p className="text-[15px] lg:text-lg">{user.mail}</p>
              </div>
            </div>

            <button
              onClick={() => {
                setModalUserData(true)
                setShowUserInfo(!showUserInfo)
              }}
              className="bg-cyan text-white py-3 w-full rounded-lg shadow-1 cursor-pointer"
            >
              Modificar datos
            </button>
            <div className="hidden lg:flex lg:justify-center">
              <p
                onClick={signOut}
                className="text-deactivatedDarkBlue font-bold text-[15px] mt-4 border-b border-deactivatedDarkBlue max-w-max cursor-pointer"
              >
                Cerrar sesión
              </p>
            </div>
          </div>
        </div>}
      </div>
      {user && <div className='flex items-center border-t gap-2 pt-3 lg:hidden' onClick={signOut}>
        <SignOutIcon />
        <p className="text-deactivatedDarkBlue font-[500] text-lg max-w-max cursor-pointer">Cerrar sesión</p>
      </div>}
    </DivOutside>
  )
}

const Header = ({ data }) => {
  const user = useSelector(state => state.auth.user)

  const { showUserInfo, setShowUserInfo } = data

  const dispatch = useDispatch()
  const showLogin = useSelector(state => state.others.showLogin)
  const setShowLogin = (value) => dispatch(othersActions.setShowLogin(value))

  const ENV = process.env.REACT_APP_ENV;

  const dataParent = {
    ...data,
    showUserInfo,
    setShowUserInfo,
    user,
    setShowLogin,
    showLogin,
  }

  return (
    <header className='h-[8vh] lg:h-[10vh] py-5 md:py-0 max-h-[10vh] flex items-center justify-between px-4 sm:px-6 sm:px-10 xl:px-20 shadow-5 md:shadow-3 bg-white'>
      <Link to={'/agenda'}>
        <img src='/assets/Logo.png' alt="D'Agustino Logo" className='hidden md:block md:!h-[3.5vh] lg:!h-[5vh]' />
        <p className='md:hidden text-darkGrey2 text-[15px] sm:text-lg min-w-max'>Laboratorio <span className='font-bold'>D'Agostino Bruno</span></p>
        {(ENV !== undefined && ENV !== null &&  ENV !== "production")&&
        <label className='ambiente'>{(ENV !== undefined && ENV !== null &&  ENV !== "production") ? ENV : ''}</label>
  }
      </Link>

      <nav className='flex items-center gap-5 relative'>
        <div className='flex items-center gap-5 lg:gap-10 text-sm md:text-[15px] lg:text-[20px]'>
          <Link to='/agenda' className='hidden lg:block'>Pedir turno</Link>
          <Link to='/turnos' className='hidden lg:block'>Mis turnos</Link>
          <Link to='/resultados' className='hidden lg:block'>Mis resultados</Link>
          {user.role === roles.doctor && <Link to='/pacientes' className='hidden lg:block'>Pacientes</Link>}
          {user ? (
            <button className='hidden lg:block' onClick={() => setShowUserInfo(!showUserInfo)}>
              <UserIcon />
            </button>
          ) : (
            <Link to="/login"
              // onClick={() => setShowLogin(!showLogin)} 
              className='text-cyan text-sm sm:text-[15px] hidden lg:block lg:text-[20px] min-w-max'
            >
              Iniciar sesión
            </Link>
          )}
          <button className='lg:hidden' onClick={() => setShowUserInfo(!showUserInfo)}>
            <MaterialIcon icon="menu" />
          </button>
        </div>
        {showLogin && <Login data={{ setShowLogin, showUserInfo, setShowUserInfo }} />}
      </nav>
      {showUserInfo && <UserMenu data={dataParent} />}
    </header>
  )
}

export default Header
