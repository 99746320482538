import axios from "axios"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { MaterialIcon } from "../../components/Icons"
import authActions from "../../redux/actions/auth"
import pdfExample from './pdfExample.json'

export const useResultsList = () => {
  const [ results, setResults ] = useState([])
  const [errorModal, setErrorModal] = useState({show: false, text: ""})
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const searchResults = () => {
    const url = `${BE_URL}/external/api/v1/user/requests?idType=${user?.idType}&idNumber=${user?.idNumber}`

    axios.get(url, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        // console.log(res.data);
        if(res.data) setResults(res.data)
      })
      .catch(error => {
        console.log(error);
        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }

  const downloadPDF = (number) => {
    const url = `${BE_URL}/external/api/v1/user/requests/${number}/pdf?idType=${user?.idType}&idNumber=${user?.idNumber}`

    axios.get(url, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        console.log(res.data); //20908017

        if(res.data.filedata) {
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:application/pdf;base64,${res.data.filedata}`;
          downloadLink.download = res.data.filename;
          downloadLink.click()
        } else if (res.data.errorCode === 200) {
          const isAdministrative = res.data.errorMessage.includes('cuota permitida')

          setErrorModal({
            show: true, 
            text: "",
            title: `${isAdministrative ? 'Trámite administrativo pendiente de finalización' : 'Su solicitud está en proceso'}. Ante cualquier duda comuníquese por alguno de los siguientes medios:`,
            type: "info"
          })
        }
      })
      .catch(error => {
        console.log(error);

        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
        else {
          toast({
            title: 'Error descargando el archivo', 
            desc: 'Intentá nuevamente en unos minutos.',
            type: "error",
            icon: <MaterialIcon icon="error" extrastyles="text-white" />
          })
        }
      })
  }
 
  useEffect(() => {
    searchResults()
  }, [])

  return {
    results, downloadPDF, user, errorModal, setErrorModal
  }
}